import App from './App'
import React from 'react'
import Road from './Components/Road'
import Query from './Components/Query'
import About from './Components/About'
import ReactDOM from 'react-dom/client'
import Career from './Components/Career'
import Ongoing from './Components/Ongoing'
import Contact from './Components/Contact'
import Airport from './Components/Airport'
import Tunnels from './Components/Tunnels'
import Completed from './Components/Completed'
import AdvisoryServices from './Components/AdvisoryServices'
import TransportPlanning from './Components/TransportPlanning'
import UrbanInfrastructure from './Components/UrbanInfrastructure'
import {createBrowserRouter,RouterProvider} from "react-router-dom"

const router = createBrowserRouter([{
  path: "/",
  element: <App/>,
},{
  path: "/career",
  element: <Career/>,
},{
  path: "/contact",
  element: <Contact/>,
},{
  path: "/about",
  element: <About/>,
},{
  path: "/road",
  element: <Road/>,
},{
  path: "/query",
  element: <Query/>,
},{
  path: "/ongoing",
  element: <Ongoing/>,
},{
  path: "/airport",
  element: <Airport/>,
},{
  path: "/tunnels",
  element: <Tunnels/>,
},{
  path: "/completed",
  element: <Completed/>,
},{
  path: "/transport-planning",
  element: <TransportPlanning/>,
},{
  path: "/urban-infrastructure",
  element: <UrbanInfrastructure/>,
},{
  path: "/advisory-services",
  element: <AdvisoryServices/>,
},
]);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
//  <React.StrictMode>
  <RouterProvider router={router} />
 // </React.StrictMode>
);
