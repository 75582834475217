import React, { useEffect } from 'react'
import logo from '../assets/images/logo.png'
import logo1 from "../assets/images/logo1.png"
import completed from '../assets/images/completed.jpg'

const Completed = () => {

    useEffect(() => {
        const handleScroll = () => {
          const header = document.getElementById("myHeader");
          const sticky = header.offsetTop;
    
          if (window.scrollY > sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        };
    
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Detach the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
    {/* <!-- start page-wrapper --> */}
    <div className="page-wrapper">

        {/* <!-- start preloader --> */}
       
        {/* <!-- end preloader --> */}

          {/* <!-- Start header --> */}
         <header id="header" className="site-header header-style-1">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-6 footertop">
                            <div className="contact-info">
                                <ul>
                                    <li><i className="fa fa-phone"></i><a href="tel:+918120444777" className="white">8120444777</a></li>
                                    <li><i className="fa fa-envelope"></i><a href="mailto:info.civlmantra@gmail.com" className="white"> hr@civilmantra.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

          <nav className="navigation navbar navbar-default header" id="myHeader">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><img src={logo} alt=""/></a>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                        <button className="close-navbar"><i className="fa fa-close"></i></button>
                        <ul className="nav navbar-nav">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li><a href="/about">About Us</a></li>
                            <li className="menu-item-has-children">
                                  <a href="#">Sectors <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                    <li><a href="/Road">Roads and Bridges</a></li>
                                    <li><a href="/tunnels">Tunnels</a></li>
                                    <li><a href="/transport-planning">Transport Planning</a></li>
                                    <li><a href="/urban-infrastructure">Urban Infrastructure</a></li>
                                    <li><a href="/advisory-services">Advisory Services</a></li>
                                    <li><a href="/airport">Airport</a></li>	
                                </ul>
                                
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Projects <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                    <li><a href="/ongoing">Ongoing</a></li>
                                    <li><a href="/completed">Completed</a></li>
                                </ul>
                            </li>
								              <li><a href="#">Achievement</a></li>
                              <li><a href="/career">Career</a></li>
                              <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="request-quote">
                        <a href="/query">Query</a>
                    </div>
                </div>
            </nav>
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
            <section className="page-title"style={{background:`url(${completed}`}}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="title-breadcrumb">
                            <h2>Completed Projects</h2>
                            <ol className="breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li>Completed Projects</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div> 
        </section>        
        {/* <!-- end page-title --> */}


        {/* <!-- start about-section-s3 --> */}
        <section className="about-section-s3 section-padding">
            <div className="container">
                <div className="row">
                  <div className="col col-md-12">
                    <div className="title">
                      <h2 className="main2">Detail Design Services </h2>
                    </div>
                  </div>
                    
                  <div className="col col-md-8">
                    <div className="title">   
                      <h3>Description</h3>
                    </div>
                  </div>

                  <div className="col col-md-2">
                    <div className="title">                         
                    <h3>Length (Km)</h3>
                    </div>                    
                  </div>

                  <div className="col col-md-2">                      
                    <div className="title">
                      <h3>Client</h3>
                    </div>  
                  </div>
                </div>
		      <div className="row" id="bordered-layout">
        <div className="col col-md-8">
               <div className="title">
<p>4- Lanning of rampur Kathgodam section NH-87, from Km 0.00 ( Km 190.000 of NH 24) to Km 42.791 ( Design Chainage Km 43.446) (Package - I) in the state of Uttar Pradesh under NHDP phase- III on DBOT Hybrid Annuity mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                          
                            <p>43.46 </p>


                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>Sadbhav Engineering Ltd.

</p>                         
                       
                    </div>
                </div>
				
				
				
				
				              <div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">

<p>4 -laning of Chutmalpur-Ganeshpur section of NH-72A & Roorkee-Chutmalpur- Gagalheri to Saharanpur section of NH-73 Highway.


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                          
                            <p>54.00
</p>


                       
                    </div>
					  <div className="col col-md-2">
                      
                           
                            <p>Gawar Constructions Ltd
</p>                         
              
                    </div>
                </div>
				              <div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
  
<p>Two Lanning of Anooppur to Chechai  Road starts at existing CH.00+00 of MP-MDR to 10 Km.


</p>
                    </div>
                    </div>
                    <div className="col col-md-2">
                    
                       
                            <p>10.00

</p>


                       
                    </div>
					  <div className="col col-md-2">
                      
                           
                            <p>Atcons India Limited

</p>

                 
                    </div>
                </div>            
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Two Lanning of Anooppur to Deri Kherwa  Road starts at existing CH.00+00 of MP-MDR to 22 Km.
</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      
                            <p>  22.00 

</p>
         
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>Atcons India Limited
</p>



                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Two Lanning of Nonghati to Lilatola  to   Road starts at existing CH.00+00 of MP-MDR to 44.400 Km.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      
                            <p>     44.40 

</p>

                    </div>
					  <div className="col col-md-2">
                       
                            <p>Atcons India Limited



</p>

                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Two Lanning of Shahpur-Nachankheda starts at existing CH. 00+00 of MP-MDR-21-09 at Shahpur & terminates at Nachankheda at CH. 10.00 (New Km. Stone) of MP-MDR-21-09

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        
                            <p>10.00 </p>


                       
                    </div>
					  <div className="col col-md-2">
                     
                          
                            <p>RSK Constructions Pvt. Ltd.


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Two Lanning of Burhanpur-Bori-Borsal-Nepanagar Road Road starts at existing CH.00+00 of MP-MDR-21-12 at Burhanpur Village & terminates near Saikheda at CH. 38+550.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                            <p>38.55 
</p>


                       
                    </div>
					  <div className="col col-md-2">
                      
                            <p>RSK Constructions Pvt. Ltd.



</p>

                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Two Lanning of Shapur to Phopnar Sangrampur starts at existing CH.00+00 of MP-MDR to 18.00 Km.
</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        
                            <p>18.00

</p>

                    </div>
					  <div className="col col-md-2">
                      
                            <p>Krishna Infra (Jaipur)

</p>

                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Two Lanning of Barman to tendukhera  starts at existing CH.00+00 of MP-MDR to 18.00 Km.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                     <p>22.00 

</p>


                       
                    </div>
					  <div className="col col-md-2">
                       
                            <p>Krishna Infra (Jaipur)

</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Construction of Railway Over Bridge  and its Approaches from Nagarjun-Kuda Village to Thaheku, Dimapur Nagaland (In Association with Aecom)
</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       <p>1.35 

</p>

                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>Isack Constructions Pvt Ltd

</p>

 
                    </div>
                </div>



<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Widening to two lane with paved shoulders, strengthening and geometrics Improvement of NH-88 (New NH-103) from Km. 95/0 (Near Kandrour Bridge) to Km.  140/0 (Near Hamirpur) (New RD 85/900 to 129/235) in the State of Himachal Pradesh. (Km. 113/100 to 129/235 New Chainage) Section-III In Association with – G-Engineering Services Pvt Ltd.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       <p>16.135

</p>

                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>Ceigall India Ltd


</p>

 
                    </div>
                </div>


<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Design of Foot Over Bridge at iffco chowk NH 48 at ch. 28.965 (Design Length-0.11 Km)  (In Association with G Eng Advisory Services Pvt Ltd)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       <p>_

</p>

                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>GAWAR Constructions


</p>

 
                    </div>
                </div>

	<div className="row">
                    <div className="col col-md-12">
                           <div className="title">
                      <h2>                                          Pre Tender Engineering

	</h2>


                        </div>
                    </div>
 </div>



				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                     
<p>2 Lanning of Ahmednagar- Baramati- Phaltan (SH-60, SH-157 &SH-152),  0+000 to Km. 93+800 in the state of Maharashtra on EPC mode. (Total Length = 93.8000 Km)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      
                            <p>93.800
</p>


                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>Monte Carlo Ltd



</p>

                       
                    </div>
                </div>
				<div className="row" id="bordered-layout"> 
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>“Upgradation of Shirur - Rakshasbhuvan – Kholyachiwadi – Karegaon – Dongarkini – Chumbli – Patoda – Dighol- Kharda road (SH-62) to 2-Lane with paved shoulder/4-Lane configuration (Package 2)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>59.967
</p>

                       
                    </div>
					  <div className="col col-md-2">
  <p>Monte Carlo Ltd



</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Upgradation of paithan (pategaon) - mungi - hadgaon -Bodhegaon - ladjalgaon - ghogaspargaon - phulsangvi - Ukhandachakla - dahiwandi - shirur to 2-lane with paved Shoulder/4lane configration (Package i – 56 km) for proper Structuring and implementation of project in the state of Maharashtraon EPC mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    <p>56.233
</p>


                       
                    </div>
					  <div className="col col-md-2">
                      <p>Monte Carlo Ltd


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Upgradation of Aurangabad – Phulambri – Sillod – Ajanta – Pahur – Jalgaon  , Package-I, Aurangabad-Phulambri-Sillod, from Km. 0+000 to Km. 60+630 (Design Chainage) to two lane with paved shoulder configuration in the state of Maharashtra.


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        
                            <p>60.630
</p>

                    </div>
					  <div className="col col-md-2">
                        
                            <p>Monte Carlo Ltd


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Construction of ROB and its approaches in lieu of Level Crossing No. 10/B/T at km 307.050 of NH-60 in the state of West Bengal AND Construction of ROB and its approaches in lieu of Level Crossing No. 21/SPL/E at km 338.600 of NH-60 in the state of West Bengal on EPC mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>6.048
</p>


                       
                    </div>
					  <div className="col col-md-2">
                       <p>KCC Buildcon



</p>

                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Construction of ROB and its approaches in lieu of Level Crossing No. 6/B/T at km 284.170 on NH-60 in the State of West Bengal.(ROB at Topsi) AND  Construct- ion of ROB and its approaches in lieu of Unmanned Level Crossing at km. 303.900 on NH-60 in the State of West Bengal. (ROB at Padaveswar) on EPC mode. 

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>6.532
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>KCC Buildcon



</p>


                       
                    </div>
                </div>

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Construction of 4-lane ROB & its approaches in replacement of existing RUB (Rly. Km. 31/22-24) between Bhedia&Bolpur in Bhedia at Km 41.760 of NH-2B in the State of West Bengal.(ROB at Bhedia) and Construction of 4Lane ROB (Twins 2-lanes) & its approaches in replacement of level crossing at Km 3.950 on NH-2B (Railway Km 226/0) at Talit in the State of West Bengal. (ROB at Talit) on EPC mode.


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p> 4.361

</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>
KCC Buildcon


</p>


                       
                    </div>
                </div>

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Rehabilitation and Up  gradation to 2-lane with paved shoulder configuration  of Nandakumar – Contai –Digha- Chandaneswar- road  from km 0.00 to km 25.50 & from km 41.00 to km 64.70 of NH-116B on EPC mode  in the state of West Bengal under annual plan 2016-2017.


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>49.200
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>KCC Buildcon



</p>


                       
                    </div>
                </div>

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Four Laning of Wardha-Butibori Section of NH-361 from km 465.500 to km 524.690 (design length 59.190 km) under NH (O) in the State of Maharashtra on Hybrid Annuity Mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>59.190
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>Monte Carlo Ltd



</p>


                       
                    </div>
                </div>

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Four Laning of Loha –Waranga section of NH361 from km 176.800 to km 244.369 (design length 67.569 km) under NH(O) in the State of Maharashtra on Hybrid Annuity Mode. 

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>67.570
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>Monte Carlo Ltd



</p>


                       
                    </div>
                </div>

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Rehabilitation and up-gradation to two lanes with paved shoulder from Km 531/250 t km 597/946 (kunkuri to CH/ JH Border Section) of NH 78 in the state of Chattisgarh under NHDP IV on EPC mode. In Association with G Eng Advisory Services

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>66.696
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>GAWAR Constructions



</p>


                       
                    </div>
                </div>

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>
Upgradation to 6 lane with paved shoulders of Sayla - Bamanbore section of NH 8A (New NH 47) from Km. 138+000 to Km. 182+490 on EPC mode in the State of Gujarat

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>44.490
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>Monte Carlo Ltd



</p>


                       
                    </div>
                </div>




						<div className="row" id="bordered-layout">




                    <div className="col col-md-8">
                           <div className="title">
                    

<p>Upgradation to 6 lane with paved shoulders of Rajkot- Bamanbore section of NH 8B (New NH 27) from Km. 185+230 to km. 216+000 on EPC mode in the State of Gujarat


</p>
                        </div>


                    </div>
                    <div className="col col-md-2">
                        
                          <p>30.770
</p>
     </div>
					  <div className="col col-md-2">
                       <p>Monte Carlo Ltd




</p>


                       
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>2 Lanning with paved shoulder for NH3 in the state of Maharashtra (section-I from phaltan - gondavale- mayani-vita) package no: NH/MSRDC/16 ... Existing chainage : ( km 140/570 to 230/000 km) , design chainage :( km 0/000 to 89/430 km) on EPC mode

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                            <p>89.43  
</p>



                       
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd



</p>

                    </div>
               </div>



						<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Jammu ring road/bypass starts from km 85.312 of NH-1A (South West side) and ends at km 15.068 of Nagrota bypass of NH-1A. This bypass/ring road is to be constructed with 4-lane configuration of rigid and flexible type pavement composition in plain and hill section respectively. 



</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       <p>58.20 
</p>

                    </div>
					  <div className="col col-md-2">
                        
                            <p>Punj Lloyd Ltd



</p>

                    </div>
           </div>
						



<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>4 Lane ROB and its approaches in lieu of existing Chainage 92-C at Km 63 on NH-503 Ext. in Nangal near Ajouli Mour in the State of Punjab.(In association with G eng)



</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>4.36 
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       <p>PS Engineers Pvt Ltd



</p>

                    </div>
              
						</div>


<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>“Construction of Dimapur Bypass (Assam Portion) of 4/6 lane pavement  on EPC basis  from existing Km 159.400 of NH-36 to existing Km 102.500 of NH-39 and upto end point of Assam portion [Design Km 118.050 to design Km 132.375] (length 14.325 Km)  in the State of Assam under Phase-A of SARDPNE”



</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>14.35  
</p>

                    </div>
					  <div className="col col-md-2">
                        <p>Valecha Ltd




</p>

                    </div>
</div>
               


<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Four lane for delinked stretch for 28.70 km from km 624.480 to km 653.225(delinked stretch of Seoni-MP/MH Border) of NH-7 under NHDP Phase-II on EPC Mode in the state of MP.



</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    <p>28.70 
</p>


                       
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd



</p>

                </div>
</div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Four/Six laning of Karodi (km 320.104) to Telwadi (375.000) Road section of NH-211(new NH no. 52); (Existing length 54.896 km; Design length 55.610 km) in the state of Maharashtra under NHDP Phase IV-B on EPC Mode.


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>54.89 
</p>


                       
                    </div>
					  <div className="col col-md-2">
                       <p>Apco Infratech




</p>

                       
                    </div>
              </div>
					

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and upgradation of 4 laning of Cuttack – Angul Section of NH-42 (new NH-55) from km 0.400 to km 60.200 (Pkg-I) in the State of Odisha under NHDP PHASE-III on EPC mode.



</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>59.80 
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Barbrik Projects Limited



</p>

                       
                    </div>
               
					</div>



<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and Up- gradation from 2 lane to 4 lane of NH Stretch under NHDP-IVB for Varanasi Dagamagpur (Pkg-1) Section of NH-7 on EPC Mode in the State of Uttar Pradesh & Design Chainage Km15+100 to km 49+100.



</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>34.00 
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Gannon & Dunkerley Ltd




</p>


                       
                    </div>
             </div>
			 <div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Construction Of 4-Lane Nh-56 Bypass Connecting Nh-2 ( Rakhauna Village Km 782.546 Of Nh-2) With Nh-56 (Vajidpur Village Km 271.300 Of Nh-56) As Part Of Varanasi Ring Road , (Phase-Ii, Pkg-I), Design Chainage Km 0.000 To Km 16.400 (Total Length 16.980 Km Including Interchange Loop At Nh-2) In The State Of Uttar Pradesh Under Nhdp Phase -Vii On Epc Mode




</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>16.98 
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Gannon & Dunkerley Ltd





</p>


                       
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Widening, Strengthening and reconstruction of Sawai Madhopur Sheopur Road KM 76/600 to 112/00 NH-552 Extn in the State of Rajasthan on Engineering, Procurement and Construction EPC Mode




</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>35.40
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Atcons Ltd.





</p>


                       
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation & Upgradation of 2 lane flexible pavement to 2 lane with paved shoulders Rigid Pavement on Shujalpur-Ashta Road from Km 41+800 to 82+300 & White Topping (inside Ashta city around which bypass has been proposed in Length of Km 3.84) on Shujalpur to Ashta section of Newly declared NH-752 C in the State Of Madhya Pradesh” on EPC mode.




</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>40.50 
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Bhavna Engineering Ltd





</p>


                       
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and upgradation of existing 2lane to four laning of Koida (Km 206.200) to Rajamunda (km 259.453) section of NH-215 (New NH- 520) in the state of Odisha under NHDP Phase III, Package II on EPC Mode 




</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>53.25 
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd





</p>


                       
                    </div>
             </div>
					
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p> Rehabilitation and up-gradation of existing 2-lane to four lane standards from Talchar to End of Kamakhyanagar Bypass Section of (Km. 8.844 – Km. 15.725) of NH 23 (New NH – 149) and (Km. 301.875 – Km. 335.946) of NH 200 (New NH 53) in the State of Odisha Under NHDP Phase - III on EPC Mode (Pkg-I).</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>40.95 
</p>
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd</p>                   
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">

<p>Rehabilitation and Up gradation of existing 2-lane to 4-lane standards to End of Kamakhyanagar Bypass – Duburi Section (km 335.946 –km 388.382) of NH-200 (New NH 53) in the State of Odisha Under NHDP Phase – III on EPC Mode (Pkg-II).</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>52.43 </p>
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd</p>    
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
<p>Rehabilitation and Up gradation of existing 2lane to 4-lane standards from Duburi to Chandikhole Section of NH 200 (New NH 53) from km. 388.376 to km 428.074 in the State of Odisha Under NHDP Phase - III on EPC Mode (Pkg- III).</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>39.70 </p>
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd</p>    
                    </div>
             </div>
                </div>
	
        </section>
        {/* <!-- end about-section-s3 -->    */}


        {/* <!-- start cta-section-s2 --> */}
        <section className="cta-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="cta-text">
                            <h3>We provide innovative Product Solutions for sustainable progress.</h3>
                            <a href="#" className="theme-btn-s2">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div> 
        </section>   
        {/* <!-- end cta-section-s2 --> */}


        {/* <!-- start site-footer --> */}
          <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-3 col-sm-12">
                            <div className="widget about-widget">
                                <div className="footer-logo"><img src={logo1} alt=""/></div>
                                <p>We are committed to client delight through operational excellence, innovation, and continual improvement of quality.</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/CivilMantra/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/CivilMantra-infrastructure-consultant/?originalSubdomain=in" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                               
                                </ul>
                            </div>
                        </div>

						       <div className="col col-md-3 col-sm-12">
                            <div className="widget twitter-feed-widget">
                                <h3>Address</h3>
                                <ul>
                                    <li>
                                        <div className="text">
                                            <p>3rd FLOOR, Dlf Corporate Green,
                                            <br/>TOWER 3A, <br/> SECTOR 74A,
                                            GURUGRAM - 122004 INDIA.
                                            </p>
                                        </div>
                                       
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col col-md-2 col-sm-12">
                            <div className="widget services-widget">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><a href="/index"><i className="fa fa-angle-right"></i> Home</a></li>
                                    <li><a href="/about"><i className="fa fa-angle-right"></i> About Us</a></li>
                                 <li><a href="/career"><i className="fa fa-angle-right"></i> Career</a></li>
                            
                                    <li><a href="/contact"><i className="fa fa-angle-right"></i> Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
						  <div className="col col-md-4 col-sm-12">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1535464875656!2d77.04134909329177!3d28.41462337288375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2337f459d30d%3A0x1da15404a6d62df4!2sCivilMantra+Infracon+Private+Limited!5e0!3m2!1sen!2sin!4v1530883613116" width="100%" height="272" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                        </div>

                 

                 
                    </div>
                </div> 
            </div> 
           <div className="copyright-info">
                <div className="container">
                    <p className="footerpara">2024 &copy; All Rights Reserved. Website designed by <a href="https://aimantra.co" target="blank">AiMantra</a></p>
                </div>
            </div>
        </footer>
        {/* <!-- end site-footer --> */}
    </div>
    {/* <!-- end of page-wrapper --> */}
    </>
  )
}

export default Completed