import './App.css';
import { useEffect } from 'react';
import map from "./assets/images/map.jpg"
import logo from './assets/images/logo.png'
import logo1 from "./assets/images/logo1.png"
import logo2 from "./assets/images/logo2.png"
import logo3 from "./assets/images/logo3.png"
import logo4 from "./assets/images/logo4.png"
import logo5 from "./assets/images/logo5.png"
import logo6 from "./assets/images/logo6.png"
import tunnel from "./assets/images/tunnel.jpg"
import urban1 from "./assets/images/urban1.jpg"
import airport from "./assets/images/airport.jpg"
import advisory from "./assets/images/advisory.jpg"
import transport from "./assets/images/transport.jpg"
import icon1 from "./assets/images/fun-fact/icon-1.png"
import icon2 from "./assets/images/fun-fact/icon-2.png"
import mantravideo from "./video/Mantra.mp4"
import icon3 from "./assets/images/fun-fact/icon-3.png"
import logo1a from "./assets/images/partners/logo-1.png"
import logo2a from "./assets/images/partners/logo-2.png"
import logo3a from "./assets/images/partners/logo-3.png"
import logo4a from "./assets/images/partners/logo-4.png"
import logo5a from "./assets/images/partners/logo-5.png"
import logo6a from "./assets/images/partners/logo-6.png"
import logo7a from "./assets/images/partners/logo-7.png"
import logo8a from "./assets/images/partners/logo-8.png"
import logo9a from "./assets/images/partners/logo-9.png"
import FD2021min from "./assets/images/foundation/FD2021-min.jpg"
import DSC2237min from "./assets/images/foundation/DSC2237-min.jpg"
import DSC2264min from "./assets/images/foundation/DSC2264-min.jpg"
import DSC2287min from "./assets/images/foundation/DSC2287-min.jpg"
import DSC2309min from "./assets/images/foundation/DSC2309-min.jpg"
import FD20211min from "./assets/images/foundation/FD20211-min.jpg"
import FD20212min from "./assets/images/foundation/FD20212-min.jpg"
import FD20213min from "./assets/images/foundation/FD20213-min.jpg"
import Collages1 from "./assets/images/foundation/Collages1-min.jpg"
import highwayConstruction from "./assets/images/highway-construction.jpg"

import "../src/assets/css/animate.css"
import "../src/assets/css/owl.carousel.css"
import "../src/assets/css/owl.theme.css"
import "../src/assets/css/slick.css"
import "../src/assets/css/slick-theme.css"
import "../src/assets/css/owl.transitions.css"
import "../src/assets/css/jquery.fancybox.css"
import "../src/assets/css/magnific-popup.css"
import "../src/assets/css/style.css"
import "../src/assets/css/bootstrap.min.css"
import "../src/assets/css/font-awesome.min.css"
import "../src/assets/css/career.css";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSpring, animated } from 'react-spring';

function App() {
    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
    }

    // Spring
    function Counter({n}){
        const {number} = useSpring({
            from:{number:0},
            number:n,
            delay:200,
            config:{mass:1,tension:20, friction:10},
        });
        return <animated.div>{number.to((n)=>n.toFixed(0))}</animated.div>
    }

    useEffect(() => {
        const handleScroll = () => {
          const header = document.getElementById("myHeader");
          const sticky = header.offsetTop;
    
          if (window.scrollY > sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        };
    
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Detach the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
{/* <!-- start page-wrapper --> */}
<div className="page-wrapper">

    {/* <!-- Start header --> */}
     <header id="header" className="site-header header-style-1">
        <div className="topbar">
            <div className="container">
                <div className="row">
                  
                    <div className="col col-sm-6 footertop">
                        <div className="contact-info">
                            <ul>
                                <li><i className="fa fa-phone"></i><a href="tel:+918120444777" className="white">8120444777</a></li>
                                <li><i className="fa fa-envelope"></i><a href="mailto:info.civlmantra@gmail.com" className="white"> hr@civilmantra.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

      <nav className="navigation navbar navbar-default header" id="myHeader">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="open-btn">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="/"><img src={logo} alt=""/></a>
                </div>
                <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                    <button className="close-navbar"><i className="fa fa-close"></i></button>
                    <ul className="nav navbar-nav">
                        <li>
                            <a href="/">Home</a>
                      
                        </li>
                        <li><a href="/about">About Us</a></li>
                        <li className="menu-item-has-children">
                              <a href="/" rel='noreferrer'>Sectors <i className="fa fa-sort-desc"></i></a>
                            <ul className="sub-menu">
                                <li><a href="/Road">Roads and Bridges</a></li>
                                <li><a href="/tunnels">Tunnels</a></li>
                                <li><a href="/transport-planning">Transport Planning</a></li>
                                <li><a href="/urban-infrastructure">Urban Infrastructure</a></li>
                                <li><a href="/advisory-services">Advisory Services</a></li>
                                <li><a href="/airport">Airport</a></li>	
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="/" rel='noreferrer'>Projects <i className="fa fa-sort-desc"></i></a>
                            <ul className="sub-menu">
                                <li><a href="/ongoing">Ongoing</a></li>
                                <li><a href="/completed">Completed</a></li>  
                            </ul>
                        </li>
                        <li><a href="/" rel='noreferrer'>Achievement</a></li>
                        <li><a href="/career">Career</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div className="request-quote">
                    <a href="/query">Query</a>
                </div>
            </div>
        </nav>
    </header>
    {/* <!-- end of header --> */}


    {/* <!-- start of hero -->    */}
    <section className="hero hero-slider-wrapper hero-style-2">
      <video id="video-01" autoPlay loop muted style={{width: "100%"}}>
        <source src={mantravideo} type="video/mp4"/>
        <source src={mantravideo} type="video/webm"/>
        Your browser does not support video tag.
      </video>
    </section>
    {/* <!-- end of hero slider --> */}

    <section className="fun-fact-section">
        <h2 className="hidden">Fun fact</h2>
        <div className="container">
            <div className="row start-count">
                <div className="col col-md-5 col-xs-4">
                    <div className="grid">
                        <div className="icon">
                            <img src={icon1} alt=""/>
                        </div>
                        <h3>
                            <span className="counter" data-count="70" style={{display:'flex'}}><Counter n={1900}/><span>+</span></span>
                        </h3>
                        <p>projects</p>
                    </div>
                </div>
                <div className="col col-md-4 col-xs-4">
                    <div className="grid">
                        <div className="icon">
                            <img src={icon2} alt=""/>
                        </div>
                        <h3>
                            <span className="counter" data-count="30" style={{display:'flex'}}><Counter n={350}/><span>+</span></span>
                        </h3>
                        <p>Clients</p>
                    </div>
                </div>
                <div className="col col-md-3 col-xs-4">
                    <div className="grid">
                        <div className="icon">
                            <img src={icon3} alt=""/>
                        </div>
                        <h3>
                            <span className="counter" data-count="100" style={{display:'flex'}}><Counter n={300}/><span>+</span></span>
                        </h3>
                        <p>Employees</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- end fun-fact-section --> */}

    {/* <!-- start about-section-s2 --> */}
    <section className="about-section-s2">
        <div className="container">
            <div className="row">
                <div className="col col-md-6">
                    <div className="about-text">
                        <div className="about-title">
                            <span>About Us</span>
                            <h2>We are professional enterprise dedicated to Project Consultancy,  Construction Management and Structural Design </h2>
                        </div>
                        <p>CivilMantra is an Engineering & Management Consultancy provide services for Design and Construction Supervision, Pre-bid services for National Highway  Authority of India and Ministry of Surface Transport, Government of India and Private Developers/ Contractors.</p>
                        <a href="/about" className="theme-btn-s2">Read More</a>
                    </div>
                </div>
      <div className="contactform">
        <div className="col col-lg-6 col-sm-12">
          <img className="image1" src={map} alt=''/>
            <p className="para1">CivilMantra has worked for a number of public and private
            clients providing planning, environmental, engineering,
            surveying and Pre-Bidding, construction management services.
            </p>
        </div>
      </div> 
    </div>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.8.2/css/lightbox.min.css"/>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.8.2/js/lightbox.min.js"></script>
    
    <div className="lightbox-gallery">
                <div className="container">
                    <div className="intro">
                      <h2 className="text-center">CivilMantra family celebrating 6th foundation day</h2>
                    </div>
                    <div className="row photos">
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={DSC2237min} data-lightbox="photos">
                            <img className="img-fluid" src={DSC2237min} alt=""/>
                            </a>
                        </div>
                        
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={DSC2264min} data-lightbox="photos">
                            <img className="img-fluid" src={DSC2264min} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={DSC2287min} data-lightbox="photos">
                            <img className="img-fluid" src={DSC2287min} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={DSC2309min} data-lightbox="photos">
                            <img className="img-fluid" src={DSC2309min} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={Collages1} data-lightbox="photos">
                            <img className="img-fluid" src={Collages1} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={FD2021min} data-lightbox="photos">
                            <img className="img-fluid" src={FD2021min} alt=""/>
                            </a>
                        </div>
                        
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={FD20211min} data-lightbox="photos">
                            <img className="img-fluid" src={FD20211min} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={FD20212min} data-lightbox="photos">
                            <img className="img-fluid" src={FD20212min} alt=""/>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 item">
                            <a href={FD20213min} data-lightbox="photos">
                            <img className="img-fluid" src={FD20213min} alt=""/>
                            </a>
                        </div>
        
                    </div>
                </div>
            </div>
    
  
    <div className="logo section">
      <img className="image2" src={logo6} alt=''/>
      <img className="image2" src={logo2} alt=''/>   
      <img className="image2" src={logo3} alt=''/>
      <img className="image2" src={logo4} alt=''/>
      <img className="image2" src={logo5} alt=''/>
    </div>
    </div>
    </section>

    {/* <!-- end about-section-s2 --> */}
        <section className="projects-section section-padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1">
                  <div className="section-title">
                    <h2>Sectors</h2>
                  </div>
                </div>
            </div>

            <div className="row">
                <div className="sortable-gallery">
                 
                  <div className="gallery-container" >
                    <div className="row">
                        <div className=" col col-md-2 box construction" >
          
                            <img src={highwayConstruction} alt=""/>
                            <div className="details">
                                <div className="info">  
                                    <h3><a href="/Road">Roads & Bridges</a></h3>
                                </div>
                            </div> 
                        </div>

                        <div className="col col-md-2 box construction plant">
                            <img src={tunnel} alt=""/>
                            <div className="details">
                                <div className="info">   
                                    <h3><a href="/tunnels">Tunnels</a></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col col-md-2 box plant construction">
                            <img src={transport} alt=""/>
                            <div className="details">
                                <div className="info">
                                    <h3><a href="/transport-planning">Transport Planning</a></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col col-md-2 box construction mechanical">
                            <img src={urban1} alt=""/>
                            <div className="details">
                                <div className="info">          
                                    <h3><a href="/urban-infrastructure">Urban Infrastructure</a></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col col-md-2 box plant welding">
                            <img src={advisory} alt=""/>
                            <div className="details">
                                <div className="info">           
                                    <h3><a href="/advisory-services">Advisory Services</a></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col col-md-2 box mechanical">
                            <img src={airport} alt=""/>
                            <div className="details">
                                <div className="info">
                                    <h3><a href="/airport">Airport</a></h3>
                                </div>
                            </div>
                        </div> 
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </section>


    {/* <!-- end services-section-s2 --> */}


    {/* <!-- start features-section --> */}
    <section className="about-section">
        <div className="content">
            <div className="left-col">
                <div className="inner">
                    <h3>Our Objective</h3>
                    <p>To achieve the intent through effective application and implementation of resources, so as to attain maximum results that help achieve the prime objectives of our clients.
                      To constantly innovate, upgrade and revolutionize our methods so as to provide the very  best  and  latest  in  consultancy  services  adhering to  the  highest  quality standards.
                    </p>
                    <div className="social">
                        <span>Get Connected With Us:</span>
                        <ul>
                            <li><a href="/" rel='noreferrer'><i className="fa fa-facebook-square"></i></a></li>
                            <li><a href="/" rel='noreferrer'><i className="fa fa-twitter-square"></i></a></li>
                            <li><a href="/" rel='noreferrer'><i className="fa fa-instagram"></i></a></li>
                            <li><a href="/" rel='noreferrer'><i className="fa fa-linkedin-square"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="right-col">
                
            </div>
        </div>
    </section>
    {/* <!-- end features-section -->


    <!-- start partners-section-s2 -->
   
    <!-- end partners-section-s2 --> */}

<div className="container">

<section className="customer-logos slider">
<Slider {...settings}>
  <div className="slide"><img src={logo1a} alt=''/></div>
  <div className="slide"><img src={logo2a} alt=''/></div>
  <div className="slide"><img src={logo3a} alt=''/></div>
  <div className="slide"><img src={logo4a} alt=''/></div>
  <div className="slide"><img src={logo5a} alt=''/></div>
  <div className="slide"><img src={logo6a} alt=''/></div>
  <div className="slide"><img src={logo7a} alt=''/></div>
  <div className="slide"><img src={logo8a} alt=''/></div>
  <div className="slide"><img src={logo9a} alt=''/></div>
  </Slider>
</section>
</div>



    {/* <!-- start site-footer --> */}
    <footer className="site-footer">
        <div className="upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-md-3 col-sm-12">
                        <div className="widget about-widget">
                            <div className="footer-logo"><a href="/" rel='noreferrer'><img src={logo1} alt=""/></a></div>
                            <p>We are committed to client delight through operational excellence, innovation, and continual improvement of quality.</p>
                            <ul>
                                <li><a href="https://www.facebook.com/CivilMantra/" target="_blank" rel='noreferrer'><i className="fa fa-facebook"></i></a></li>
                                <li><a href="/" rel='noreferrer'><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/CivilMantra-infrastructure-consultant/?originalSubdomain=in" target="_blank" rel='noreferrer'><i className="fa fa-linkedin"></i></a></li>
                                <li><a href="/" rel='noreferrer'><i className="fa fa-google-plus"></i></a></li>
                           
                            </ul>
                        </div>
                    </div>

               <div className="col col-md-3 col-sm-12">
                        <div className="widget twitter-feed-widget">
                            <h3>Address</h3>
                            <ul>
                                <li>
                                    <div className="text">
                                        <p>3rd FLOOR, Dlf Corporate Green,
                                        <br/>TOWER 3A, <br/> SECTOR 74A,
                                        GURUGRAM - 122004 INDIA.
                                        </p>
                                    </div>
                                   
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-2 col-sm-12">
                        <div className="widget services-widget">
                            <h3>Quick Links</h3>
                            <ul>
                                <li><a href="/"><i className="fa fa-angle-right"></i> Home</a></li>
                                <li><a href="/about"><i className="fa fa-angle-right"></i> About Us</a></li>
                                <li><a href="/career"><i className="fa fa-angle-right"></i> Career</a></li>
                        
                                <li><a href="/contact"><i className="fa fa-angle-right"></i> Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-4 col-sm-12">
                       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1535464875656!2d77.04134909329177!3d28.41462337288375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2337f459d30d%3A0x1da15404a6d62df4!2sCivilMantra+Infracon+Private+Limited!5e0!3m2!1sen!2sin!4v1530883613116" 
                       width="100%" height="272" frameborder="0" style={{border:"0"}} allowfullscreen title='iframe'></iframe>
                    </div>
             
                </div>
            </div>
        </div> 
        <div className="copyright-info">
            <div className="container">
                <p className="footerpara">2024 &copy; All Rights Reserved. Website designed by <a href="https://aimantra.co" target="blank">AiMantra</a></p>
            </div>
        </div>
    </footer>
</div>

{/* <script>
    var vid = document.getElementById("bgvid");
    var pauseButton = document.querySelector("#polina button");

    if (window.matchMedia('prefers-reduced-motion').matches) {
        vid.removeAttribute("autoplay")
        vid.pause()
        pauseButton.innerHTML = "Paused"
    }

    function vidFade() {
        vid.classNameList.add("stopfade");
    }

    vid.addEventListener('ended', function(){
        // only functional if "loop" is removed 
        vid.pause();
        // to capture IE10
        vidFade();
    }); 


    pauseButton.addEventListener("click", function() {
        vid.classNameList.toggle("stopfade");
        if (vid.paused) {
            vid.play();
            pauseButton.innerHTML = "Pause";
        } 
        else {
            vid.pause();
            pauseButton.innerHTML = "Paused";
        }
    })

</script> */}


{/* <script>
$(document).ready(function(){
$('.customer-logos').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [{
        breakpoint: 768,
        settings: {
            slidesToShow: 4
        }
    }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 3
        }
    }]
});
});
</script> */}
{/* <script>
window.onscroll = function() {myFunction()};

var header = document.getElementById("myHeader");
var sticky = header.offsetTop;

function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classNameList.add("sticky");
  } else {
    header.classNameList.remove("sticky");
  }
}
</script> */}
  </>
);
}

export default App;
