import React, { useEffect } from 'react'
import logo from '../assets/images/logo.png'
import logo1 from "../assets/images/logo1.png"
import highwayconstruction from "../assets/images/highway-construction.jpg"

const Road = () => {

    useEffect(() => {
        const handleScroll = () => {
          const header = document.getElementById("myHeader");
          const sticky = header.offsetTop;
    
          if (window.scrollY > sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        };
    
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Detach the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
    {/* <!-- start page-wrapper --> */}
    <div className="page-wrapper">

    

             {/* <!-- Start header --> */}
         <header id="header" className="site-header header-style-1">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                      
                        <div className="col col-sm-6 footertop">
                            <div className="contact-info">
                                <ul>
                                    <li><i className="fa fa-phone"></i><a href="tel:+918120444777" className="white">8120444777</a></li>
                                    <li><i className="fa fa-envelope"></i><a href="mailto:info.civlmantra@gmail.com" className="white"> hr@civilmantra.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 

          <nav className="navigation navbar navbar-default header" id="myHeader">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><img src={logo} alt=""/></a>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                        <button className="close-navbar"><i className="fa fa-close"></i></button>
                        <ul className="nav navbar-nav">
                            <li>
                                <a href="/">Home</a>
                          
                            </li>
                            <li><a href="about ">About Us</a></li>
                            <li className="menu-item-has-children">
                                  <a href="#">Sectors <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                   
                                    <li><a href="/Road">Roads and Bridges</a></li>
                                    <li><a href="/tunnels">Tunnels</a></li>
                                    <li><a href="/transport-planning">Transport Planning</a></li>
                                    <li><a href="/urban-infrastructure ">Urban Infrastructure</a></li>
                                    <li><a href="/advisory-services ">Advisory Services</a></li>
                                    <li><a href="/airport ">Airport</a></li>	
                                </ul>
                                
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Projects <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                    <li><a href="/ongoing">Ongoing</a></li>
                         
                                    <li><a href="/completed">Completed</a></li>
                                   
                           
                                </ul>
                            </li>
							 <li><a href="#">Achievement</a></li>
							
                             <li><a href="/career">Career</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div> 
                    <div className="request-quote">
                        <a href="/query">Query</a>
                    </div>
                </div> 
            </nav>
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="title-breadcrumb">
                            <h2>Roads and Bridges</h2>
                            <ol className="breadcrumb">
                                <li><a href="index ">Home</a></li>
                                <li>Sectors</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div> 
        </section>        
        {/* <!-- end page-title --> */}


  
        {/* <!-- end all-services-section --> */}
		  <section className="about-section-s3 section-padding" id="gray">
            <div className="container">
              <div className="row">
                    <div className="col col-md-6">
                        <div className="about-img">
                            <img src={highwayconstruction} alt=""/>
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <div className="about-text" id="less-padding">
                            <div className="title">
                                
                                <h2>Roads and Bridges
</h2>
                            </div>
                            <p>Roads have been existing in India for the last 5000 years. In early stages of Indian history, Ashoka and Chandragupta made efforts to construct roads. But the real progress was made during the Mughal period to enhance their empire networking.

Roads make a crucial contribution to economic development and growth and bring important social benefits. They are of vital importance in order to make a nation grow and develop. In addition, providing access to employment, social, health and education services makes a road network crucial in fighting against poverty.

As per the National Highways Authority of India, about 65% of freight and 80% passenger traffic is carried by the roads.

As India continues its forward march as a global economic power, her urban transport network is fast becoming the most important infrastructure to support its mega growth.
</p>
<p className="listing">Project Management / Development Consultancy
</p>
<ul className="listing" id="simple-list">
<li>Bridge Management System using MBIU
</li>
<li>Detailed Project Reports/Feasibility Studies
</li>
<li>Traffic Studies and Surveys & Demand Assessment
</li>
<li>Engineering Surveys & Investigations
</li>
<li>Preliminary Design of Roads / Bridges / Structures
</li>
<li>Preparation of Cost Estimates
</li>
<li>IE Services and Project Supervision
</li>
<li>Road Safety Work; Tolling Assistance
</li>
<li>Authority Engineering Services
</li>
</ul>
                          
                        </div>

                       
                    </div>
                </div>
				 </div> 
        </section>
<section className="services-section-s2 section-padding">
            <div className="container">
                

                <div className="row">
                    <div className="col col-xs-12">
					    <h2 className="paraheading" style={{textAlign:"left"}}>The staggering statistics tells it all  </h2>
						

<ul className="listing1" id="simple-list">
<li>2 million kilometres of roads with almost 960,000 kilometers of surfaced roads</li>
 <li>Fifty-three highways, almost 20,000 kilometers in length</li>   
<li>National highways carry about 40 percent of road traffic</li>
<li>Around 60 percent of all passenger traffic travels by road.
</li> 
</ul>

           
                    </div>
                </div>

                               
            </div>
        </section>





     {/* <!-- start site-footer --> */}
         <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-3 col-sm-12">
                            <div className="widget about-widget">
                                <div className="footer-logo"><img src={logo1} alt=""/></div>
                                <p>We are committed to client delight through operational excellence, innovation, and continual improvement of quality.</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/CivilMantra/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/CivilMantra-infrastructure-consultant/?originalSubdomain=in" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                               
                                </ul>
                            </div>
                        </div>

						       <div className="col col-md-3 col-sm-12">
                            <div className="widget twitter-feed-widget">
                                <h3>Address</h3>
                                <ul>
                                    <li>
                                        <div className="text">
                                            <p>3rd FLOOR, Dlf Corporate Green,
                                            <br/>TOWER 3A, <br/> SECTOR 74A,
                                            GURUGRAM - 122004 INDIA.
                                            </p>
                                        </div>
                                       
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col col-md-2 col-sm-12">
                            <div className="widget services-widget">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><a href="/index"><i className="fa fa-angle-right"></i> Home</a></li>
                                    <li><a href="/about"><i className="fa fa-angle-right"></i> About Us</a></li>
                                    <li><a href="/career"><i className="fa fa-angle-right"></i> Career</a></li>
                                    <li><a href="/contact"><i className="fa fa-angle-right"></i> Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
						  <div className="col col-md-4 col-sm-12">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1535464875656!2d77.04134909329177!3d28.41462337288375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2337f459d30d%3A0x1da15404a6d62df4!2sCivilMantra+Infracon+Private+Limited!5e0!3m2!1sen!2sin!4v1530883613116" width="100%" height="272" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                        </div>

                 

                 
                    </div>
                </div> 
            </div> 
            <div className="copyright-info">
                <div className="container">
                    <p className="footerpara">2024 &copy; All Rights Reserved. Website designed by <a href="https://aimantra.co" target="blank">AiMantra</a></p>
                </div>
            </div>
        </footer>
        {/* <!-- end site-footer --> */}
    </div>
    {/* <!-- end of page-wrapper --> */}
    </>
  )
}

export default Road