import React, { useEffect } from 'react'
import logo from '../assets/images/logo.png'
import logo1 from "../assets/images/logo1.png"
import ongoing from "../assets/images/ongoing.jpg"

const Ongoing = () => {

    useEffect(() => {
        const handleScroll = () => {
          const header = document.getElementById("myHeader");
          const sticky = header.offsetTop;
    
          if (window.scrollY > sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        };
    
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Detach the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
    {/* <!-- start page-wrapper --> */}
    <div className="page-wrapper">

        {/* <!-- start preloader --> */}
       
        {/* <!-- end preloader --> */}

        {/* <!-- Start header --> */}
         <header id="header" className="site-header header-style-1">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                      
                        <div className="col col-sm-6 footertop">
                            <div className="contact-info">
                                <ul>
                                    <li><i className="fa fa-phone"></i><a href="tel:+918120444777" className="white">8120444777</a></li>
                                    <li><i className="fa fa-envelope"></i><a href="mailto:info.civlmantra@gmail.com" className="white"> hr@civilmantra.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 

          <nav className="navigation navbar navbar-default header" id="myHeader">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><img src={logo} alt=""/></a>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                        <button className="close-navbar"><i className="fa fa-close"></i></button>
                        <ul className="nav navbar-nav">
                            <li>
                                <a href="/">Home</a>
                          
                            </li>
                            <li><a href="/about">About Us</a></li>
                            <li className="menu-item-has-children">
                                  <a href="#">Sectors <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                   
                                    <li><a href="/Road">Roads and Bridges</a></li>
                                    <li><a href="/tunnels">Tunnels</a></li>
                                    <li><a href="/transport-planning">Transport Planning</a></li>
									<li><a href="/urban-infrastructure">Urban Infrastructure</a></li>
									<li><a href="/advisory-services">Advisory Services</a></li>
									<li><a href="/airport">Airport</a></li>	
                                </ul>
                                
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Projects <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                    <li><a href="/ongoing">Ongoing</a></li>
                         
                                    <li><a href="/completed">Completed</a></li>
                                   
                           
                                </ul>
                            </li>
								 <li><a href="#">Achievement</a></li>
                             <li><a href="/career">Career</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="request-quote">
                        <a href="/query">Query</a>
                    </div>
                </div>
            </nav>
        </header>
        {/* <!-- end of header --> */}


        {/* <!-- start page-title --> */}
            <section className="page-title"style={{background:`url(${ongoing})`}}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="title-breadcrumb">
                            <h2>Ongoing Projects</h2>
                            <ol className="breadcrumb">	
                                <li><a href="/">Home</a></li>
                                <li>Ongoing Projects</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>        
        {/* <!-- end page-title --> */}


        {/* <!-- start about-section-s3 --> */}
        <section className="about-section-s3 section-padding">
            <div className="container">
                <div className="row">
				<div className="col col-md-12">
                           <div className="title">
                      
						   <h2 className="main2">Detail Design Services 
</h2>
 </div>
                    </div>
				
                    <div className="col col-md-8">
                           <div className="title">
                      
	<h3>Description
</h3>

                        </div>
                    </div>
                    <div className="col col-md-2">
                        
                            <div className="title">
                               
                               
								 <h3>Length (Km)
</h3>
                            </div>
                            
                    </div>
					  <div className="col col-md-2">
                      
                            <div className="title">
							
                               							 <h3>Client</h3>
           
                            </div>
                          

                    </div>
                </div>
				
				
				              <div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">

<p>4-laning of Aligarh-Kanpur section from Km 229.000 (Design Chainage 240.897) to Km 289.000 (Design Chainage 302.108) (Package-III from Kalyanpur- Naviganj) of NH-91 in the state of Uttar Pradesh on Hybrid Annuity Mode under Bharatmala Pariyojana. Project Cost (1357 Cr)


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                          
                            <p>61.22</p>


                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>Apco


</p>                         
                       
                    </div>
                </div>
				
				
				
				
			
				              <div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
  
<p>“Up-gradation to two lane with paved shoulder configuration from  undal to Jhadol (section of NH-58e), from km. 0+000 to km. 43+900 (design  hainage), package-i, in the state of Rajasthan

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                       
                            <p>43.90

</p>


                       
                    </div>
					  <div className="col col-md-2">
                      
                           
                            <p>HG Infra 
</p>

                 
                    </div>
                </div> 
                	              <div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">

<p>Rehabilitation & Upgradation of Pachore–Shujalpur Road from Km 2+700 to Km 33+000 & White Topping (inside Pachore and Shujalpur city around which bypass has been proposed in Length of Km 9.61) on Pachore to Shujalpur section of Newly declared    
NH-752 Cin the State of Madhya Pradesh on EPC mode”.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                          
                            <p>39.91
</p>


                       
                    </div>
					  <div className="col col-md-2">
                      
                           
                            <p>Montecarlo-BECPL-JV

</p>                         
              
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>4- Lanne project Highway Comprises the section of NH-87, from Km 42.791 (Design Chainage  Km 43.446) to Km 88.00 ( Design Chainage Km 93.226) ( Package- II)  Rampur Kathgodam in the state of Uttarkhand .
</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      
                            <p>49.78
</p>

                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>Sadbhav Engineering Ltd.

</p>



                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and augmentation of New NH-354  of section Khemkaran town to Start of Amritsar bypass from Design Chainage km 22.673 to km 71.496 or Length of 48.823 km  under Bharatmala scheme in the state of Punjab on EPC Mode (Pkg-II)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      
                            <p>      48.83 

</p>

                    </div>
					  <div className="col col-md-2">
                       
                            <p>Ceigall – IMC JV


</p>

                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Strengthening and Widening / Reconstruction of Chatterpur - Japla  Road to Two lane with pave shoulder on EPC Mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                            <p>33.00</p>
                    </div>
					  <div className="col col-md-2">
                     
                          
                            <p>Barbrik Projects Limited


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Construction of Connectivity between Northern Mahipalpur bypass Road,NH8 and Northern Access Road from Airport near Hanuman Mandir SH: C/o main Underpass crossing NH-8, Bridge near utility area of Airport and Flyover at Junction of Mehrauli- Mahipalpur Bypass Road along with Grade Separators at other locations, Loops, Ramps, Footpath, Road Work including Road Signage, Road/Street Lighting works, drainage, rain water harvesting etc

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                            <p>Junction Improvement 
</p>


                       
                    </div>
					  <div className="col col-md-2">
                      
                            <p>Gawar Constructions Ltd


</p>

                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>4 -laning of Chutmalpur-Ganeshpur section of NH-73 Gagalheri to Saharanpur section from Chainage 35.00 to 86 Highway.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        
                            <p>51.00

</p>

                    </div>
					  <div className="col col-md-2">
                      
                            <p>Gawar

</p>

                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Up-gradation of Mansar- Ramtek-Salaikhurd, from existing km 75+800 (Mansar) to km 120+100 (Salaikhurd) [Design km.0.000 to km.44.000] (44.000 km)ofNH-753 to Two lane with paved shoulder configuration in the state of Maharashtra on EPC mode”. 

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                     <p>44.00

</p>


                       
                    </div>
					  <div className="col col-md-2">
                       
                            <p>Barbrik Projects Limited


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Up-gradation Of Salaikhurd- Nawegaon-Tirora, From Existing Km 120+100 (Salaikhurd) To Km 163+200 (Tirora) [Design Km. 44.000 To Km. 87.000] Excluding 0.80 Km. Length From Km. 58+700 To Km. 59+500 (ROB At Tumsar) (42.200 Km) of NH- 753 To Two Lane With Paved Shoulder configuration in the state of Maharashtra on EPC mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       <p>42.20

</p>

                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>Barbrik Projects Limited


</p>

 
                    </div>
                </div>

	<div className="row">
                    <div className="col col-md-12">
                           <div className="title">
                      <h2>Project Management Consultancy
	</h2>


                        </div>
                    </div>
 </div>



				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                     
<p>4 Lanning Gwalior to Jhansi from (Design Chainage-16.000 to 96.127) of NH-75 in th states of Madhya Pradesh/Uttar Pradesh, to a four lane divided configuration along with strengthening of existing two lanes.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      
                            <p>90.13
</p>


                       
                    </div>
					  <div className="col col-md-2">
                        
                            <p>KRC Infra Projects Pvt ltd (Palwal)

</p>

                       
                    </div>
                </div>
				<div className="row" id="bordered-layout"> 
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>6 Lanning Dewas bypass from 0.00 km to 19.80 km in the state of Madhya Pradesh. (BOT ROAD PROJECT)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>19.80
</p>

                       
                    </div>
					  <div className="col col-md-2">
  <p>K N Devlopers & Buildcon Pvt Ltd.


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Prepration of Detail Project Report for Widening and Upgradation of Road from Hero Honda Chowk to SPR Junction Near Bassai Village in Gurgaon Haryana (In Association with KY Consultants Pvt Ltd)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    <p>5.50 
</p>


                       
                    </div>
					  <div className="col col-md-2">
                      <p>Muncipal Corporation Gurgaon


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Prepration of Detail Project Report for Widening and Upgradation of Railway Road from Rajiv Chowk Chowk  to Gurugram Gurgaon Railway Station  in Gurgaon Haryana (In Association with KY Consultants Pvt Ltd)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        
                            <p>9.00
</p>

                    </div>
					  <div className="col col-md-2">
                        
                            <p>Muncipal Corporation Gurgaon


</p>


                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Prepration of Feasibility Study of Junction Improovment  (Flyover/Underpass's)  at Palla Chowk, Kherigaon Chowk, Sec 29 Chowk in Faridabad Haryana (In Association with KY Consultants Pvt Ltd)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>3.50
</p>


                       
                    </div>
					  <div className="col col-md-2">
                       <p>Muncipal Corporation Gurgaon

</p>

                       
                    </div>
                </div>
				<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Prepration of Detail Project Report for Widening and Upgradation of Chauma Road from Rajendera Chowk  toDelhi Gurugram Border near Sector 112  in Gurgaon Haryana (In Association with KY Consultants Pvt Ltd)

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    
                           
                            <p>6.50 
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       
                          
                            <p>Muncipal Corporation Gurgaon


</p>


                       
                    </div>
                </div>

<div className="row">
<div className="col col-md-12">
                           <div className="title">
                      <h2>Pre Tender Services</h2>

                        </div>


                    </div>

</div>


						<div className="row" id="bordered-layout">




                    <div className="col col-md-8">
                           <div className="title">
                    

<p>Four Laning of Gandhigram (Godda) to Hansdiha section of NH-133 from Km 50.800 to Km 95.770 in the State of Jharkhand on EPC Mode.

</p>
                        </div>


                    </div>
                    <div className="col col-md-2">
                        
                          <p>44.90
</p>
     </div>
					  <div className="col col-md-2">
                       <p>Gannon & Dunkerley Company  Ltd



</p>


                       
                    </div>
             </div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>4-laning Section from Km. 1.700 (Pirpaiti of NH-80) in the State of Bihar to Km. 50.800 (Gandhigram, Godda of NH-133) in the State of Jharkhand on EPC mode.

</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       
                            <p>49.10  
</p>



                       
                    </div>
					  <div className="col col-md-2">
                        <p>Gannon & Dunkerley Ltd



</p>

                    </div>
               </div>



						<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>4-laning of Aligarh-Kanpur section from Km 229.000 (Design Chainage km 240.897) to Km 289.000 (Design Chainage km 302.108) (Package-III from Kalyanpur- Naviganj) of NH-91 in the state of Uttar Pradesh on Hybrid Annuity Mode under Bharatmala Pariyojana


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                       <p>61.21
</p>

                    </div>
					  <div className="col col-md-2">
                        
                            <p>Apco Infratech Pvt Ltd



</p>

                    </div>
           </div>
						



<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Four laning of Sangli-Solapur (Package-IV: Mangalwedha to Solapur) Section of NH-166 from existing Ch. km. 314.969 to Ch. 370.452 (Design Ch. km. 321.600 to km. 378.100) of length 56.500 km in the state of Maharashtra on Hybrid Annuity Mode


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>56.50 
</p>



                       
                    </div>
					  <div className="col col-md-2">
                       <p>Apco Infratech Pvt Ltd



</p>

                    </div>
              
						</div>


<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and upgradation of 2 lane road with paved shoulders of mangrulpir to mahan (nh 161 a from 97/074 to 64/574 ) [design km. 75/000 to km. 107/500] (total length = 32.5 km) in the state of maharashtra


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>61.80 
</p>

                    </div>
					  <div className="col col-md-2">
                        <p>Arcons Infrastructures & Constructions Pvt. Ltd.



</p>

                    </div>
</div>
               


<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and upgradation of mahan to akola (nh 161a from 64.574 to 35.265) [design km. 107/500 to km. 136/809] (total length = 29.309 km) in the state of maharashtra.


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                    <p>61.80
</p>


                       
                    </div>
					  <div className="col col-md-2">
                        <p>Arcons Infrastructures & Constructions Pvt. Ltd.



</p>

                </div>
</div>
					<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Up-gradation of Wardha – Arvi from km 0+000 (Wardha) to km 53+708 (Arvi) to Two Lane with paved shoulders in the state of Maharashtra 


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>67.40 
</p>


                       
                    </div>
					  <div className="col col-md-2">
                       <p>Arcons Infrastructures & Constructions Pvt. Ltd.



</p>

                       
                    </div>
              </div>
					

<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Rehabilitation and upgradation of existing carriageway to two lanes with paved shoulder standards in Arvi to Talegaon section of NH – 347A from km 99/700 to km 86/000 on EPC mode in the State of Maharashtra


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                      <p>67.40
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Arcons Infrastructures & Constructions Pvt. Ltd.


</p>

                       
                    </div>
               
					</div>



<div className="row" id="bordered-layout">
                    <div className="col col-md-8">
                           <div className="title">
                      

<p>Upgradation to 4 lane with paved shoulder of Bhimasar Junction of NH-41 to Anjar-Bhuj up to Airport Junction from km 0.000 to km 65.478 of NH-341 to be executed on Hybrid Annuity Mode in the state of Gujarat


</p>
                        </div>
                    </div>
                    <div className="col col-md-2">
                        <p>65.47
</p>

                       
                    </div>
					  <div className="col col-md-2">
                        <p>Monte Carlo Ltd



</p>


                       
                    </div>
             </div>
					

                </div>
        </section>
        {/* <!-- end about-section-s3 --> */}


      
        {/* <!-- start cta-section-s2 --> */}
        <section className="cta-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="cta-text">
                            <h3>We provide innovative Product Solutions for sustainable progress.</h3>
                            <a href="#" className="theme-btn-s2">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>   
        {/* <!-- end cta-section-s2 --> */}


        {/* <!-- start site-footer --> */}
         <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-3 col-sm-12">
                            <div className="widget about-widget">
                                <div className="footer-logo"><img src={logo1} alt=""/></div>
                                <p>We are committed to client delight through operational excellence, innovation, and continual improvement of quality.</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/CivilMantra/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/CivilMantra-infrastructure-consultant/?originalSubdomain=in" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                               
                                </ul>
                            </div>
                        </div>

						       <div className="col col-md-3 col-sm-12">
                            <div className="widget twitter-feed-widget">
                                <h3>Address</h3>
                                <ul>
                                    <li>
                                        <div className="text">
                                            <p>3rd FLOOR, Dlf Corporate Green,
                                            <br/>TOWER 3A, <br/> SECTOR 74A,
                                            GURUGRAM – 122004 INDIA.
                                            </p>
                                        </div>
                                       
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col col-md-2 col-sm-12">
                            <div className="widget services-widget">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><a href="/index"><i className="fa fa-angle-right"></i> Home</a></li>
                                    <li><a href="/about"><i className="fa fa-angle-right"></i> About Us</a></li>
                                  <li><a href="/career"><i className="fa fa-angle-right"></i> Career</a></li>
                            
                                    <li><a href="/contact"><i className="fa fa-angle-right"></i> Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
						  <div className="col col-md-4 col-sm-12">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1535464875656!2d77.04134909329177!3d28.41462337288375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2337f459d30d%3A0x1da15404a6d62df4!2sCivilMantra+Infracon+Private+Limited!5e0!3m2!1sen!2sin!4v1530883613116" width="100%" height="272" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                        </div>

                 

                 
                    </div>
                </div>
            </div> 
             <div className="copyright-info">
                <div className="container">
                    <p className="footerpara">2024 &copy; All Rights Reserved. Website designed by <a href="https://aimantra.co" target="blank">AiMantra</a></p>
                </div>
            </div>
        </footer>
        {/* <!-- end site-footer --> */}
    </div>
    {/* <!-- end of page-wrapper --> */}
    </>
  )
}

export default Ongoing