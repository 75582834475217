import React, { useEffect } from 'react'
import logo from '../assets/images/logo.png'
import logo1 from "../assets/images/logo1.png"
import quality from '../assets/images/quality.png'
import abouts3 from '../assets/images/about-s3-pic.jpg'
import img1 from '../assets/images/testimonials/img-1.jpg'
import icon1 from "../assets/images/services/icon-1.png"
import icon2 from "../assets/images/services/icon-2.png"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


// import required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay,A11y } from 'swiper/modules';

const About = () => {

    useEffect(() => {
        const handleScroll = () => {
          const header = document.getElementById("myHeader");
          const sticky = header.offsetTop;
    
          if (window.scrollY > sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        };
    
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Detach the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
    {/* <!-- start page-wrapper --> */}
    <div className="page-wrapper">

        {/* <!-- start preloader --> */}
       
        {/* <!-- end preloader --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
            <div className="topbar">
                <div className="container">
                    <div className="row">          
                        <div className="col col-sm-6 footertop">
                            <div className="contact-info">
                                <ul>
                                    <li><i className="fa fa-phone"></i><a href="tel:+918120444777" className="white">8120444777</a></li>
                                    <li><i className="fa fa-envelope"></i><a href="mailto:info.civlmantra@gmail.com" className="white"> hr@civilmantra.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

                    <nav className="navigation navbar navbar-default" id='myHeader'>
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><img src={logo} alt=""/></a>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                        <button className="close-navbar"><i className="fa fa-close"></i></button>
                        <ul className="nav navbar-nav">
                            <li>
                                <a href="/">Home</a>
                          
                            </li>
                            <li><a href="/about">About Us</a></li>
                            <li className="menu-item-has-children">
                                 <a href="#">Sectors <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                   
                          <li><a href="/Road">Roads and Bridges</a></li>
                                    <li><a href="/tunnels">Tunnels</a></li>
                                    <li><a href="/transport-planning">Transport Planning</a></li>
									<li><a href="/urban-infrastructure">Urban Infrastructure</a></li>
									<li><a href="/advisory-services">Advisory Services</a></li>
									<li><a href="/airport">Airport</a></li>	
                                </ul>
                                
                            </li>
                       <li className="menu-item-has-children">
                                <a href="#">Projects <i className="fa fa-sort-desc"></i></a>
                                <ul className="sub-menu">
                                    <li><a href="/ongoing">Ongoing</a></li>
                         
                                    <li><a href="/completed">Completed</a></li>
                                   
                           
                                </ul>
                            </li>
								 <li><a href="#">Achievement</a></li>
                              <li><a href="/career">Career</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="request-quote">
                        <a href="/query">Query</a>
                    </div>
                </div>
            </nav>
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title" style={{background:"url(assets/images/banner2.jpg);"}}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="title-breadcrumb">
                            <h2>About Company</h2>
                            <ol className="breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li>About Company</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>        
        {/* <!-- end page-title --> */}


        {/* <!-- start about-section-s3 --> */}
        <section className="about-section-s3 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6">
                        <div className="about-img">
                            <img src={abouts3} alt=""/>
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <div className="about-text">
                            <div className="title">
                                <span>About Us</span>
                                <h2>We Build Everything</h2>
                            </div>
                            <p>We CivilMantra INFRACON PVT. LTD. are committed to provide services to client delight through operational execution, innovation and continual improvement during execution.The Company head office is situated in GURUGRAM.</p>
                            <p>CivilMantra is an Engineering & Management Consultancy providing services for Design and Construction Supervision, Pre-bid services for National Highway  Authority of India and Ministry of Surface Transport, Government of India and Private Developers/ Contractors.</p>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>

        <section className="services-section-s2 section-padding">
            <div className="container">
                

                <div className="row">
                    <div className="col col-xs-12">
                        <div className="services-grids services-slider-s2">
                            <div className="grid">
                                <div className="box" id="half-clm">
                                    <div className="service-title">
                                        <div className="icon"><img src={icon1} alt=""/></div>
                                        <h3>Our Objective</h3>
                                    </div>
                                    <p>
                                        The objective of CivilMantra is to introduce the latest techniques, and to provide professional consultancy in multi disciplinary areas of structural engineering, bridge engineering, highway engineering, traffic and transportation engineering, water resources engineering environmental engineering and engineering management.
                                        The company offers services in various disciplines of civil engineering ranging from planning, topographic survey, traffic studies, feasibility studies, preliminary and detailed engineering and computer aided design, to construction supervision, training and institutional strengthening.
                                    </p>
                                  
                                </div>
                                <div className="box" id="half-clm">
                                    <div className="service-title">
                                        <div className="icon"><img src={icon2} alt=""/></div>
                                        <h3>Our Vision</h3>
                                    </div>
                                    <p>
                                        Our aim is to maintain the highest standards in the projects we undertake; Meticulous Planning in every field and attention to minute details are elements that CivilMantra employees strives to achieve, together as a team with a proper blend of resources and efficiency at every stage of project implementation.
                                        “We are a network of experts working with clients and colleagues to develop and implement innovative solutions to complex tasks and challenges.”
                                        “We pledged ourselves to deal with challenges and to ensure that the projects undertaken by us are cost effective, time bound and proper utilization of manpower and resources scientifically and efficiently”.
                                    </p>
                                </div>
                            </div>
                            <div className="grid">
                            </div>
                        </div>
                    </div>
                </div>

                               
            </div>
        </section>
        {/* <!-- end services-section-s2 --> */}


        {/* <!-- start work-process-section --> */}
        <section className="work-process-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-4">
                        <div className="section-title-s4">
                            <span>Quality Policy</span>
                            <h2>How We Work</h2>
							<img src={quality}/>
                        </div>
                    </div>
                    <div className="col col-lg-8 col-md-8">
                        <p>At CivilMantra, we believe that our relationship shall be constructive with our entire esteemed clients and their needs remain strong and alive.</p>
                        <p>We are committed to client delight through operational excellence, innovation, and continual improvement of quality.</p>
                        <p>High level of service quality and integrity through a process of continuous improvement in all areas of operations by adhering to specifications and documented procedures by continuous up gradation of technology is the major concern in our policy.</p>
                        <p>It is our mission to provide service that will adhere to Performance, Punctuality and Price to meet the ever changing needs of the client.</p>
                    </div>
                </div>   
            </div> 
        </section>
        {/* <!-- end work-process-section --> */}


        {/* <!-- start cta-section-s2 --> */}
        <section className="cta-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="cta-text">
                            <h3>We provide innovative Product Solutions for sustainable progress.</h3>
                            <a href="/contact" className="theme-btn-s2">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>   
        {/* <!-- end cta-section-s2 --> */}

        {/* <!-- start testimonials-section-s2 --> */}
        <section className="testimonials-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-4">
                        <div className="section-title-s2">
                            <h2>Reviews of <br/>our clients</h2>
                        </div>
                    </div>
                    <div className="col col-lg-8 col-md-8">
                        <p>Apart from having a nucleus of expert talent within the system, the Company has the standing arrangement of engaging the services of Associates as and when there is an opportunity to utilize their distilled talent. We connect expertise across services to deliver innovative as well as transformative outcomes. </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-11 col-lg-offset-1">
                        <div className="testimonials-grids testimonials-slider">
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                              }}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            <SwiperSlide>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={img1} alt=""/>
                                </div>
                                <div className="details">
                                    <h3>“professional approach, timely delivery and quality work”</h3>
                                    <span className="client-info">- Montecarlo</span>
                                    <p>" We appreciate thier professional approach, timely delivery and quality work which has been to Complete Satisfaction"</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={img1} alt=""/>
                                </div>
                                <div className="details">
                                    <h3>“Impressive Performance & Productivity”</h3>
                                    <span className="client-info">- Sadbhav</span>
                                    <p>the project was  substantially completed and deliverable of the requirement of scope of services in due time.We satisfied with the quality of delivered services.</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end testimonials-section-s2 --> */}


        {/* <!-- start contact-info-map-section --> */}
        <section className="contact-info-map-section">
            <div className="content">
                <div className="left-col">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.5915863461014!2d77.04232541735105!3d28.413729279452745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2337f459d30d%3A0x1da15404a6d62df4!2sCivilMantra+Infracon+Private+Limited!5e0!3m2!1sen!2sin!4v1530886051392" width="100%" height="450" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                </div>
                <div className="right-col">
                    <div className="inner">
                        <div className="title-text">
                            <h3>Drop us a line</h3>
                            <p className="white">For any kind of query, contact us with the details below.</p>
                        </div>
                        <div className="contact-info">
                            <ul>
                                <li><i className="fa fa-phone"></i> <a href="tel:+91-9857000222" className="white">+91-9857000222</a></li>
                                <li><a href="mail-to:services@CivilMantra.com" className="white"><i className="fa fa-envelope"></i> services@civilmantra.com</a></li>
                                <li><i className="fa fa-home"></i>
                                    3rd FLOOR, Dlf Corporate Green,
                                    TOWER 3A, SECTOR 74A,
                                    GURUGRAM - 122004 INDIA.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end contact-info-map-section --> */}

        {/* <!-- start site-footer --> */}
         <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-3 col-sm-12">
                            <div className="widget about-widget">
                                <div className="footer-logo"><img src={logo1} alt=""/></div>
                                <p>We are committed to client delight through operational excellence, innovation, and continual improvement of quality.</p>
                                <ul>
                                    <li><a href="https://www.facebook.com/CivilMantra/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/CivilMantra-infrastructure-consultant/?originalSubdomain=in" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                               
                                </ul>
                            </div>
                        </div>

						    <div className="col col-md-3 col-sm-12">
                            <div className="widget twitter-feed-widget">
                                <h3>Address</h3>
                                <ul>
                                    <li>
                                        <div className="text">
                                            <p>
                                                3rd FLOOR, Dlf Corporate Green,
                                                <br/>TOWER 3A, <br/> SECTOR 74A,
                                                GURUGRAM - 122004 INDIA.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-md-2 col-sm-12">
                            <div className="widget services-widget">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><a href="/"><i className="fa fa-angle-right"></i> Home</a></li>
                                    <li><a href="/about"><i className="fa fa-angle-right"></i> About Us</a></li>
                               <li><a href="/career"><i className="fa fa-angle-right"></i> Career</a></li>
                            
                                    <li><a href="/contact"><i className="fa fa-angle-right"></i> Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
						  <div className="col col-md-4 col-sm-12">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1535464875656!2d77.04134909329177!3d28.41462337288375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2337f459d30d%3A0x1da15404a6d62df4!2sCivilMantra+Infracon+Private+Limited!5e0!3m2!1sen!2sin!4v1530883613116" width="100%" height="272" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                        </div>                 
                    </div>
                </div>
            </div> 
          <div className="copyright-info">
                <div className="container">
                    <p className="footerpara">2024 &copy; All Rights Reserved. Website designed by <a href="https://aimantra.co" target="blank">AiMantra</a></p>
                </div>
            </div>
        </footer>
        {/* <!-- end site-footer --> */}
    </div>
    {/* <!-- end of page-wrapper --> */}
    </>
  )
}

export default About